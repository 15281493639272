import React from 'react'

const AdminProductScreen = () => {
	return (
		<div>
			<h1>All Products Screen</h1>
		</div>
	)
}

export default AdminProductScreen
