import React from 'react'

const DashboardScreen = () => {
	return (
		<div>
			<h1>Dashboard Screen</h1>
		</div>
	)
}

export default DashboardScreen
